import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['listingDetailsPage']

  listingDetailsPageTargetConnected() {
    document.addEventListener("keydown", handleListingDetailsShortcuts);
  }

  listingDetailsPageTargetDisconnected() {
    document.removeEventListener("keydown", handleListingDetailsShortcuts);
  }
}


function handleListingDetailsShortcuts(event) {

  if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
    switch (event.code) {
      case 'KeyC': // C for copy
        event.preventDefault();
        var copyShortURLButton = document.getElementById('copy-short-url-button');
        copyShortURLButton.click();
        break;
      case 'KeyP': // P for print
        event.preventDefault();
        var printButton = document.getElementById('print-button');
        printButton.click();
        break;
      case 'KeyL': // L for favorite
        event.preventDefault();
        var favoriteButton = document.getElementById('favorite-button');
        favoriteButton.click();
        break;
      case 'KeyS': // S for share
        event.preventDefault();
        var shareButton = document.getElementById('share-button');
        shareButton.click();
        break;
      default:
        break;
    }

  }

}
